.welcome__footer {
  &-logo {
	position: relative;
	display: block;
	margin-bottom: 0.66rem;

	&:before {
	  content: "";
	  position: absolute;
	  display: block;
	  width: 250%;
	  height: 1px;
	  background-color: var(--color-light);
	  top: 50%;
	  left: -260%;
	  transform: translate3d(0, -50%, 0);
	}

	&:after {
	  content: "";
	  position: absolute;
	  display: block;
	  width: 250%;
	  height: 1px;
	  background-color: var(--color-light);
	  top: 50%;
	  left: 110%;
	  transform: translate3d(0, -50%, 0);
	}
  }

  &-providers {
	position: relative;
	margin: 0 auto;
	padding: 0.66rem 0 2rem;
	opacity: 0.5;
	width: 55rem;
	max-width: 85%;

	picture,
	img {
	  max-height: 2rem;

	  @media (min-width: 768px) {
		max-height: 3rem;
	  }
	}
  }

  &-terms {
	width: 85%;
	max-width: 24rem;

	p {
	  font-size: 0.66rem;
	  text-align: center;
	  opacity: 0.5;
	  margin-bottom: 0.66em;
	}
  }
}