.welcome__about {

  &-content {

	h1, h2 {
	  color: var(--accent-color) !important;
	  font-weight: bold;
	  margin-top: 0.5rem;
	  margin-bottom: 1rem;
	  font-size: 2rem;
	}

	p {
	  margin: 1.5em auto;
	}

  }

  &-trust-factor-image {
	position: relative;
	display: block;
	max-width: 60rem;
	margin: 2rem auto;

	img {
	  position: relative;
	  display: block;
	  max-width: 90%;
	  margin: auto;
	}
  }

}