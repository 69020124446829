@use "variables";

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

html,
body {
  font-size: var(--font-size-mobile) !important;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    font-size: var(--font-size-tablet) !important;
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-desktop) !important;
  }
}

section,
footer {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  font-family: var(--font-family) !important;
  color: var(--color-light) !important;
  text-shadow: 2px 2px 4px var(--color-dark) !important;
  line-height: 1.2em;
}

h3 {
  font-weight: bold;
  font-size: 1.4rem;
}

h4 {
  font-weight: bold;
  font-size: 1.2rem;
}

p, li {
  color: var(--color-light) !important;
  font-size: 1rem;
}

a {
  color: var(--accent-color) !important;
  text-decoration: none;
}

a:hover {
  color: var(--hover-accent-color) !important;
}

body {
  min-height: 100vh;
  margin: 0;
  background: var(--body-bg-color) !important;
}

.-z-zero {
  z-index: 0;
}

.-z-back {
  z-index: 1;
}

.-z-middle-back {
  z-index: 2;
}

.-z-middle {
  z-index: 3;
}

.-z-middle-front {
  z-index: 4;
}

.-z-front {
  z-index: 5;
}

.-z-overlay-back {
  z-index: 9991;
}

.-z-overlay-middle-back {
  z-index: 9992;
}

.-z-overlay-middle {
  z-index: 9993;
}

.-z-overlay-middle-front {
  z-index: 9994;
}

.-z-overlay-front {
  z-index: 9995;
}

.-z-ultimate {
  z-index: 99999;
}

.-center-absolute {
  @apply transform-gpu;
  left: 50% !important;
  top: 50% !important;
  transform: translate3d(-50%, -50%) !important;
}

.-center-absolute-x {
  @apply transform-gpu;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.-center-absolute-y {
  @apply transform-gpu;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.welcome__affiliate-logo {
  position: relative;
  display: block;
  margin: 1rem auto;

  figcaption {
    color: var(--color-light);
    font-size: 1rem;
    text-align: center;
  }

  picture {
    position: relative;
    display: block;
    width: 100%;
    margin: 1rem auto 0;

    img {
      margin: 0 auto 2rem;
      width: 15rem;
      max-width: 100%;
    }

    &:after {
      content: "";
      position: relative;
      display: block;
      margin: 1rem auto;
      width: 100%;
      height: 1px;
      background-color: var(--color-light);
    }
  }
}

.welcome__header {
  height: 5rem;
  background: linear-gradient(180deg, var(--brand-color), transparent) !important;
}