:root {
  --header-height: 1.5rem;
  --body-padding-top: 7rem;
  --welcome-offer-height: 80vh;
  --color-dark: #050918;
  --color-dark-transparent: #05091875;
  --color-dark-transparent-light: #05091833;
  --color-light: #f3f5fd;
  --brand-color: #1a1823;
  --body-bg-color: #1a1823;
  --accent-color: #deb04a;
  --hover-accent-color: #f4d85e;
  --font-family: "Roboto Condensed", sans-serif;
  --font-size-mobile: 20px !important;
  --font-size-tablet: 20px !important;
  --font-size-desktop: 16px !important;
}