body[data-group="RTG"] {

  a[data-registration-link] {
    position: relative;
    display: block;
    margin: 20px auto 50px;
    width: 12em;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    background-color: var(--accent-color);
    color: var(--color-dark) !important;
    padding: 20px;
    text-shadow: none !important;
    border-radius: 20px;
    box-shadow: 2px 2px 4px var(--color-dark) !important;
    transition: background-color 300ms ease-in-out;

    &:hover {
      background-color: var(--hover-accent-color) !important;
    }
  }

  .welcome__about-content {

    a[data-registration-link] {
      margin: 20px 0;
    }

  }

}