.welcome__offer {

  &-content {
	margin-top: 2rem;

	h1 {
	  font-size: 2.5rem;
	  font-weight: bold;
	  margin: 1rem;
	}

	h2 {
	  font-size: 1.75rem;
	  font-weight: bold;
	  margin:0.5rem;
	}

	h3 {
	  font-size: 1rem;
	  margin: 1rem 1rem 0;
	}

	@media (min-width: 768px) {
	  margin-top: 0;
	}

	@media (min-width: 1024px) {
	  h1 {
		font-size: 3.5rem;
	  }

	  h2 {
		font-size: 2.25rem;
	  }

	  h3 {
		font-size: 1.5rem;
	  }
	}

  }

  &-reg-wrapper {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	background: linear-gradient(33deg, #00000090, var(--brand-color-transparent-dark));
	border-radius: 20px;
	min-height: 606px;
  }

}

body[data-brand="CryptoThrillsCasino"] {

  .welcome__offer {

	&-reg-wrapper {
	  min-height: 505px;
	}

  }

}